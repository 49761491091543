export const OUTLIER_BASE_DOMAIN = process.env.REACT_APP_OUTLIER_BASE_DOMAIN || 'outlier.org'
export const OUTLIER_STUDENT_DASHBOARD_URL = process.env.REACT_APP_OUTLIER_STUDENT_DASHBOARD_URL || 'https://dashboard.outlier.org'
export const OUTLIER_ADMIN_URL = process.env.REACT_APP_OUTLIER_ADMIN_URL || 'https://admin.outlier.org'
export const OUTLIER_PARTNERS_URL = process.env.REACT_APP_OUTLIER_PARTNERS_URL || 'https://partners.outlier.org'
export const OUTLIER_API_BASE_URL = process.env.REACT_APP_OUTLIER_API_BASE_URL || 'https://api.outlier.org'

export const SET_COURSES = 'set-courses'

export const GRADING_STATUS = [
  { label: 'Grade Pending', value: false },
  { label: 'Graded', value: true }
]

export const SUBMISSION_STATUS = [
  { label: 'Late', value: false },
  { label: 'On Time', value: true }
]

export const NO_ASSIGNMENT = 'Student did not submit assignment.'

export const PARTNERSHIP = 'partnership'
export const SCHOLARSHIP = 'scholarship'

export const DECISION_APPROVED = 'Approved'
export const DECISION_DENIED = 'Denied'

export const UPLOAD_CSV = 'upload-csv'
export const DOWNLOAD_COMPLETED_CSV = 'download-completed-csv'
// used for astronomy course, all cohorts after this date are assigned to `Introduction to Astronomy V2`
export const DATE_OVERLAP_VALUE = 'Wed Jul 20 2022'
// used for college writing course, all cohorts after this date are assigned to `College Writing V2`
export const CWI_DATE_OVERLAP_VALUE = 'Sun Jan 14 2024'
export const COURSES_WITHOUT_FINAL_EXAM = [
  'philosophy',
  'philosophy.plus',
  'dataanalytics-i.cert',
  'dataanalytics-ii.cert',
  'salesoperations.cert',
  'projectmanagement.cert',
  'itsupport.cert',
  'digitalmarketing-i.cert',
  'digitalmarketing-ii.cert'
]
