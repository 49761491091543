import React from 'react'
import PropTypes from 'prop-types'
import ModalComponent from '../ModalComponent/ModalComponent'
import CloseIcon from '../../assets/icons/modal-close.svg'

import {
  ButtonWrapper,
  GrayText,
  ModalCloseIcon,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Text,
  TitleText,
  Wrapper
} from './styled'
import EditAssessmentGradeModal from './EditAssessmentGradeModal'
import { getExamGrade } from '../../utilities/examUtils'

function EditGradeOptionsModal ({
  showModalHandler,
  show,
  handleIndividualQuestionsClick,
  section,
  studentProgress,
  isUpdating,
  handleEditAssessmentModal
}) {
  const [showAssessmentModal, setShowAssessmentModal] = React.useState(false)
  const handleHideAssessmentModal = () => setShowAssessmentModal(false)
  const grade = getExamGrade(studentProgress, section)
  const title = section?.title

  return (
    <>
      <ModalComponent show={show} handleClose={showModalHandler}>
        <Wrapper>
          <ModalHeader>
            <TitleText>{title}</TitleText>
            <ModalCloseIcon
              onClick={showModalHandler}
              src={CloseIcon}
              alt='close modal icon'
            />
          </ModalHeader>
          <GrayText style={{ marginBottom: '12px' }}>Earned Grade</GrayText>
          <Text>{grade ? `${grade}%` : 'N/A'}</Text>
          <GrayText style={{ margin: '18px 0 24px 0' }}>
            Which would you like to adjust?
          </GrayText>
          <ButtonWrapper>
            <PrimaryButton onClick={handleIndividualQuestionsClick}>
              Individual questions
            </PrimaryButton>
            <SecondaryButton onClick={() => setShowAssessmentModal(true)}>
              Exam grade
            </SecondaryButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalComponent>
      {showAssessmentModal && <EditAssessmentGradeModal
        show={showAssessmentModal}
        showModalHandler={handleHideAssessmentModal}
        handleEditAssessmentModal={handleEditAssessmentModal}
        showParentModalHandler={showModalHandler}
        title={title}
        grade={grade}
        section={section}
        isUpdating={isUpdating}
        studentProgress={studentProgress}
      />}
    </>
  )
}

EditGradeOptionsModal.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleIndividualQuestionsClick: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired
}

export default EditGradeOptionsModal
