import axios from 'axios'
import axiosRetry from 'axios-retry'
import createClient from './Components/Auth0Provider/client'
import { getApiHost, API_HOST } from './config'
import { NO_ASSIGNMENT, OUTLIER_BASE_DOMAIN } from './Constants'
import { NOTIFICATIONS } from './mock'
import { getFilteredCriteria } from './utilities/CSVDownloadToolV2'
import { getUniqueCreditGrantingInstitutions } from './utilities/studentStatusUtils'
export default {
  getCourseraGrades,
  getAmazonRefundRequests,
  saveRefundDecision,
  fetchVoucherDetails,
  submitTrackedEvent,
  fetchVoucherFile,
  setVoucherStatus,
  sendDropNotification,
  enableContentGatingForCohort,
  fetchStudentVouchers,
  enableContentGatingForStudents,
  checkEmailExists,
  putAssignmentFeedbackFile,
  deleteAssignmentFeedbackFile,
  getLastOrders,
  getPaymentStatuses,
  getStudentRelationships,
  getGradeReport,
  getCohortList,
  resetStudentProgress,
  getAllChangesHistory,
  getToken,
  getStudentId,
  getExamRetakes,
  getCourses,
  getCourse,
  getOutlierCourseLicenses,
  createTokens,
  getAllCohorts,
  addNewCohort,
  getCohortById,
  updateCohort,
  putCohortTransfer,
  getStudentCourses,
  getStudentsByCohort,
  getStudentAttempts,
  getDropEligibleStudents,
  adminBulkDropStudents,
  removeStudentFromDropList,
  getVoucherAttempts,
  addNewStudent,
  addNewVipStudent,
  updateVipStudent,
  getProctorioReviewUrl,
  createVoucherCode,
  cloneStudentProgress,
  getCourseData,
  getStudentProgress,
  setStudentSectionProgress,
  loadSectionData,
  getFlags,
  getStatusModifications,
  getAssignmentModifications,
  putStudentExamModifications,
  putStudentSectionModification,
  getStudentSectionModification,
  putCodingAssignmentModifications,
  putAssignmentModifications,
  putParticipationModifications,
  getParticipationModifications,
  getAllNotifications,
  putStudentStatus,
  bulkStudentStatusUpdate,
  getStudentData,
  getStudentStatuses,
  getStatusNotes,
  getStudentSubmissions,
  getCohortMilestones,
  getWritingAssignment,
  getCodingAssignment,
  setStudentProgress,
  getFilteredStudents,
  getFilteredStudentsV2,
  getCreditGrantingInstitutions,
  putStudentData,
  getStudentActivity,
  putExamRetake,
  putKamiDocument,
  getKamiDocument,
  createKamiViewSession,
  logError,
  getYellowdigUrl,
  addAdmin,
  updateAdminPermissions,
  getTuitionVouchers,
  getCurrentUserPermissions,
  getRolesAndDescriptions,
  getUsersByRole,
  deleteAdmin,
  mapCSVHeaders,
  mapGGUAdmissionCSVHeaders,
  importStudentData,
  importGGUAdmissionDecision,
  getUTMParameters,
  updateAdmin,
  getAllAdmins,
  getStudentTokens,
  addRelationship,
  updateRelationship,
  captureWGCAnalytics,
  getDataTableMetadata,
  getTableView,
  updateTableView,
  deleteTableView,
  getTableIdValues,
  getTableFilter,
  getAmazonStudentsProgress,
  setFinalGrades,
  uploadELTCSV,
  getUploadedELTFiles,
  getExamExtensionsTable,
  getProspectsData,
  getStudentExtensionRecords,
  getStudentDataByEmailOrId,
  removeStudentCache,
  getDataBaseSchema,
  deleteRecords,
  addNewRecord,
  updateRecord,
  addNewView,
  addAssessmentExtensions,
  putAssessmentExtension,
  getStudentsCurrentGrade,
  getSyllabusData,
  getStudentCertificates,
  checkStudentProgress,
  submitEnrollment,
  mergeAccounts,
  getTableRecordComments,
  createTableRecordComment,
  getDegrees,
  getAdvisors,
  getPartnerAvailableTokens,
  putExamKeyOverride,
  getProspectDetails,
  getGGUProspects,
  approveProspect,
  updateProspectDetails,
  getGradingCompData,
  getMaxParticipationGrade,
  getCoursesLicenses,
  voidTokens,
  upsertBulkVipStudents,
  searchAIViolations,
  updateAIViolation
}

const qs = require('qs')
const defaultHeaders = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Pragma: 'no-cache', // required for iOS Safari to behave correctly
  Expires: '0'
}
axios.defaults.headers = defaultHeaders

axiosRetry(axios, {
  retries: 1,
  retryDelay: (retryCount) => retryCount * 1000,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    const isPostOrPutRequest = ['post', 'put'].includes(error?.config?.method)
    const is404Response = error?.response?.status === 404
    return !(isPostOrPutRequest || is404Response)
  },
  onRetry: (retryCount, error, requestConfig) => {
    const { status } = error?.response || {}
    const { url } = requestConfig || {}

    console.error(
      'ON Error retry: ', JSON.stringify({ retryCount, statusCode: status, url })
    )
  }
})

const Url = getApiHost()
let coursesCache = null
const courseDataCache = {}
const gradeReportCache = {}
const courseraGradesCache = {}
let studentStatusCache = null
const dataTableCache = {}
const studentSubmissionsCache = {}

async function getToken () {
  const client = await createClient()
  const token = await client.getIdToken()
  return token
}

async function getStudentId () {
  const token = await getToken()
  const url = `${Url}/student/student-id`

  const { data: { studentId } } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return studentId
}

async function getStudentActivity (studentId, courseId, cohortId) {
  const token = await getToken()
  const url =
  `${Url}/admin/student-activity/${studentId}/${courseId}/${cohortId}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    return { error: true, message: 'Something went wrong' }
  }
}

async function putCohortTransfer (studentId, courseId, cohortData) {
  const token = await getToken()
  const url = `${Url}/admin/student/transfer/${studentId}/${courseId}`
  try {
    const { data } = await axios.put(url, cohortData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    const message = e.response ? e.response.data.message : 'Something went wrong!'
    return { message }
  }
}
async function putExamRetake (studentData) {
  const token = await getToken()
  const url = `${Url}/admin/exam-retakes`
  try {
    const { data } = await axios.post(url, studentData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    const message = e.response
      ? e.response.data.message
      : 'Something went wrong!'
    return { message }
  }
}

async function putAssignmentFeedbackFile ({
  studentEmail,
  courseId,
  cohortId,
  assignmentUUID,
  formData
}) {
  const token = await getToken()
  const url =
    `${Url}/admin/assignment-feedback-file/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}`
  console.info(`API start putAssignmentFeedbackFile ${assignmentUUID}`)
  try {
    const response = await axios.put(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'multipart/form-data'
      }
    })
    console.info(`API done putAssignmentFeedbackFile ${assignmentUUID}`)
    return response
  } catch (e) {
    console.error(`API error in putAssignmentFeedbackFile ${assignmentUUID}: ${e.message}`)
    return null
  }
}

async function deleteAssignmentFeedbackFile ({
  studentEmail,
  courseId,
  cohortId,
  assignmentUUID
}) {
  const token = await getToken()
  const url =
    `${Url}/admin/assignment-feedback-file/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}`
  console.info(`API start deleteAssignmentFeedbackFile ${assignmentUUID}`)
  try {
    const response = await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info(`API done deleteAssignmentFeedbackFile ${assignmentUUID}`)
    return response
  } catch (e) {
    console.error(`API error in deleteAssignmentFeedbackFile ${assignmentUUID}: ${e.message}`)
    return null
  }
}

async function putKamiDocument (
  formData, { studentEmail, courseId, cohortId, assignmentUUID }
) {
  const token = await getToken()
  const url = `
    ${Url}/admin/kami/upload/document/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}
  `

  try {
    const { data } = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'multipart/form-data'
      }
    })

    return { data }
  } catch (e) {
    return { errorMessage: e.message }
  }
}

async function getKamiDocument (
  { studentEmail, courseId, cohortId, assignmentUUID, documentIdentifier }
) {
  const token = await getToken()
  const url = `
    ${Url}/admin/kami/document/${studentEmail}/${courseId}/${cohortId}
    /${assignmentUUID}/${documentIdentifier}
  `

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return { data }
  } catch (e) {
    return { errorMessage: e.message }
  }
}

async function createKamiViewSession (studentEmail, body) {
  const token = await getToken()
  const url = `${Url}/admin/kami/create/view-session/${studentEmail}`

  try {
    const { data } = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return { data }
  } catch (e) {
    return { errorMessage: e.message }
  }
}

async function getStudentStatuses () {
  if (studentStatusCache) return studentStatusCache

  const token = await getToken()
  const url = `${Url}/admin/student-statuses`
  const { data } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })

  studentStatusCache = data
  return data
}

async function getStudentTokens (studentEmail) {
  const token = await getToken()
  const url = `${Url}/student/tokens?studentEmail=${
    encodeURIComponent(studentEmail)}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    return { error: true, message: 'Something went wrong ' }
  }
}

async function getExamRetakes (cohortId) {
  const token = await getToken()
  const url = `${Url}/admin/exam-retakes/${cohortId}`
  const { data } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function getStatusNotes () {
  const token = await getToken()
  const url = `${Url}/admin/status-notes`
  const { data } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function getCourseraGrades ({
  courseId, cohortId, studentEmail
}) {
  const token = await getToken()
  const url = `${Url}/student/coursera-progress/${courseId}/${cohortId}/${studentEmail}`

  if (courseraGradesCache[url]) return courseraGradesCache[url]

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    courseraGradesCache[url] = data
    return data
  } catch (error) {
    console.error('Error in getCourseraGrades: ', error.message)
    return null
  }
}

async function getGradeReport (courseID, queryParams) {
  const token = await getToken()
  const url = `${Url}/student/grades/sections/${courseID}?${queryParams}&includePastCohorts=true`
  const cachedGradeReport = gradeReportCache[url]
  if (cachedGradeReport) return cachedGradeReport

  const { data } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })

  gradeReportCache[url] = data

  return data
}

async function getCourseData (courseUuid) {
  if (courseDataCache[courseUuid]) return courseDataCache[courseUuid]

  const token = await getToken()
  const url = getUrl(courseUuid)

  const { data } = await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
  courseDataCache[courseUuid] = data

  return data
}

async function getSyllabusData (cohortId) {
  const token = await getToken()
  const url = `${Url}/cohort-syllabus/${cohortId}`

  try {
    const { data } = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return data
  } catch (error) {
    console.error('Error in getSyllabusData: ', error)
    return null
  }
}

async function getStudentProgress ({
  courseId,
  cohortId,
  isCurrentCohort,
  studentEmail }) {
  const token = await getToken()
  const url = isCurrentCohort
    ? `${Url}/student/progress/${courseId}/${studentEmail}`
    : `${Url}/student/progress/${courseId}/${cohortId}/${studentEmail}`

  const { data } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

async function setStudentSectionProgress (progressKey, studentEmail, courseId, sectionData) {
  const token = await getToken()
  const url = `${Url}/admin/student-exam-grade/${progressKey}/${studentEmail}/${courseId}` // api used by admins to update student progress

  console.info(`API start setStudentSectionProgress with intent ${progressKey}`)

  const res = await axios.put(url, sectionData, {
    headers: { Authorization: `Bearer ${token}` }
  })

  console.info(`API done setStudentSectionProgress with intent ${progressKey}`)

  return res
}

async function loadSectionData (activeCourseUUID, uuid) {
  const token = await getToken()
  const url = getUrl(activeCourseUUID, uuid)

  const { data } = await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

  return data
}

async function getStatusModifications (cohortId, studentEmail) {
  if (!cohortId || !studentEmail) return
  const token = await getToken()
  const url =
    `${Url}/admin/status-modification/${studentEmail}/${cohortId}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    return { error: true, message: 'Something went wrong' }
  }
}

async function getAssignmentModifications (studentEmail, courseId) {
  const token = await getToken()
  const url =
    `${Url}/admin/assignment-grade-modification/${studentEmail}/${courseId}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    return { error: true, message: 'Something went wrong' }
  }
}

async function putStudentExamModifications (
  studentEmail,
  { courseId, cohortId },
  editData) {
  const token = await getToken()
  const url = cohortId
    ? `${Url}/admin/exam-grade-modification/${studentEmail}/${courseId}/${cohortId}`
    : `${Url}/admin/exam-grade-modification/${studentEmail}/${courseId}`
  const { data } = await axios.put(url, editData, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function putStudentSectionModification (
  studentEmail,
  { courseId, cohortId, progressKey },
  editData) {
  const token = await getToken()
  const url = cohortId
    ? `${Url}/admin/modify-section-grade/${studentEmail}/${progressKey}/${courseId}/${cohortId}`
    : `${Url}/admin/modify-section-grade/${studentEmail}/${progressKey}/${courseId}`
  try {
    const { data } = await axios.put(url, editData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    return { error: true, message: 'Something went wrong ' }
  }
}

async function getStudentSectionModification (courseId, studentEmail) {
  const token = await getToken()
  const url = `${Url}/admin/modify-section-grade/${studentEmail}/${courseId}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    return { error: true, message: 'Something went wrong ' }
  }
}

async function putStudentStatus (studentEmail, cohortId, editData) {
  const token = await getToken()
  const url = `${Url}/admin/status-modification/${studentEmail}/${cohortId}`

  try {
    const { data } = await axios.put(url, editData, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (error) {
    console.error('Error in putStudentStatus: ', error.message)
    return { success: false, message: error.message }
  }
}

async function bulkStudentStatusUpdate (cohortId, students) {
  const token = await getToken()
  const url = `${Url}/admin/bulk-status-modification/${cohortId}`

  try {
    const { data } = await axios.put(url, { students }, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return { success: true, data }
  } catch (error) {
    console.error('Error in bulkStudentStatusUpdate: ', error.message)
    return { success: false, data: error.message }
  }
}

async function putStudentData (studentId, { email }) {
  const token = await getToken()
  const url = `${Url}/admin/update-student/${studentId}`

  const { data } = await axios.put(url, { email }, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

async function resetStudentProgress (courseID, studentIdentifier) {
  const token = await getToken()
  const url = `${Url}/student/progress/reset/${courseID}/${studentIdentifier}`
  try {
    const { data } = await axios.put(url, null, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    /*
    * It seems we can't able to access the real server error message
    * So here, a placeholder is being returned to let users know the operation
    * wasn't successful.
    * As option we can use Promise instead of async/await
    * to access the real server message using axios.catch()
    */
    return { error: true, message: 'Something went wrong ' }
  }
}

async function getAllChangesHistory (courseId, studentEmail) {
  const token = await getToken()
  const url = `${Url}/admin/exam-grade-modification/${studentEmail}/${courseId}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    return { error: true, message: 'Something went wrong ' }
  }
}

// The cohort-milestones content is cached in the frontend per cohortId.
// The cache is maintained only till the user session is active.
const cohortMilestones = {}
async function getCohortMilestones (cohortId) {
  if (cohortMilestones[cohortId]) return cohortMilestones[cohortId]
  const token = await getToken()
  const url = `${Url}/admin/v2/cohort-milestones/${cohortId}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    cohortMilestones[cohortId] = data
    return data
  } catch (e) {
    return { error: true, message: 'Something went wrong ' }
  }
}

async function addNewStudent (studentData) {
  const token = await getToken()
  const url = `${Url}/student/add-new`
  try {
    const { data } = await axios.post(url, studentData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    const message = e.response ? e.response.data.message : 'Something went wrong!'
    return { message }
  }
}

async function addNewVipStudent (vipStudentData) {
  const token = await getToken()
  const url = `${Url}/student/add-new`
  try {
    const { data } = await axios.post(url, vipStudentData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    const message = e.response ? e.response.data.message : 'Something went wrong!'
    return { message }
  }
}

async function updateVipStudent (vipStudentData, studentId) {
  const token = await getToken()
  const url = `${Url}/student/update/${studentId}`

  try {
    const { data } = await axios.post(url, vipStudentData, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    const message = e.response
      ? e.response.data.error
      : 'Something went wrong!'
    return { message }
  }
}

async function createVoucherCode (codeProperties) {
  try {
    const token = await getToken()
    const url = `${Url}/admin/create-voucher`
    const { data } = await axios.post(url, codeProperties, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    const message = e.response ? e.response.data.message
      : 'Error when creating discount code'
    console.error('Error in createVoucherCode: ', message)
    return { message }
  }
}

async function cloneStudentProgress (studentData, courseId, cohortId) {
  const token = await getToken()
  const url = cohortId
    ? `${Url}/student/clone-progress/${courseId}/${cohortId}`
    : `${Url}/student/clone-progress/${courseId}`
  try {
    const { data } = await axios.put(url, studentData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    const message = e.response ? e.response.data.error : 'Something went wrong!'
    return { message }
  }
}

async function getCohortList (courseID) {
  const token = await getToken()
  const url = `${Url}/course/cohorts/${courseID}?fieldName=dateStart`
  const { data } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

async function getCourses () {
  if (coursesCache) return coursesCache

  const token = await getToken()
  const url = `${Url}/courses`
  console.log('API start getCourses')

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.log('API done getCourses')

    coursesCache = data
    return data
  } catch (e) {
    console.log('API error getCourses: ', e.message)
    return null
  }
}

async function getCourse (courseId) {
  const courses = await getCourses()

  return courses?.find((course) => course.id === courseId)
}

async function getStudentCourses (queryParams = {}) {
  const token = await getToken()
  const url = `${Url}/student/courses`
  const { data } = await axios.get(url, {
    params: { ...queryParams },
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

async function getOutlierCourseLicenses () {
  const token = await getToken()
  const url = `${Url}/admin/course-licenses`
  console.log('API start getOutlierCourseLicenses')

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.log('API done getOutlierCourseLicenses')
    return data
  } catch (e) {
    console.log('API error getOutlierCourseLicenses: ', e.message)
    return null
  }
}

async function createTokens (requestData) {
  const token = await getToken()
  const url = `${Url}/admin/create-tokens`
  try {
    const { data } = await axios.post(url, requestData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    const message = e.response ? e.response.data.message : 'Something went wrong!'
    return { message }
  }
}

async function getStudentAttempts (queryParams = {}) {
  try {
    const token = await getToken()
    const url = `${Url}/student/attempts`
    const { data } = await axios.get(url, {
      params: { ...queryParams },
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    console.error('Error fetching student attempts: ', e)
    return []
  }
}

async function getVoucherAttempts (studentId, voucherId) {
  try {
    const token = await getToken()
    const url = `${Url}/admin/voucher-attempts/${studentId}/${voucherId}`
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    console.error('Error fetching voucher attempts: ', e)
    return []
  }
}

async function getStudentsByCohort (cohortId) {
  try {
    const token = await getToken()
    const url = `${Url}/students/${cohortId}`
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    console.error('Error in getStudentsByCohort: ', e)
    return null
  }
}

async function getAllCohorts (queryParams = {}) {
  const token = await getToken()
  const url = `${Url}/cohorts`
  const { data } = await axios.get(url, {
    params: { ...queryParams },
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

async function addNewCohort (cohortData) {
  const token = await getToken()
  const url = `${Url}/admin/cohorts/add-new`
  try {
    const { data } = await axios.post(url, cohortData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    const message = e.response ? e.response.data.message : 'Something went wrong!'
    return { message }
  }
}

async function getCohortById (cohortId) {
  const token = await getToken()
  const url = `${Url}/admin/cohorts/details/${cohortId}`
  const { data } = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function updateCohort (cohortId, editData) {
  const token = await getToken()
  const url = `${Url}/admin/cohorts/update/${cohortId}`
  const { data } = await axios.put(url, editData, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function getStudentData (studentEmail) {
  const token = await getToken()
  const url = studentEmail
    ? `${Url}/student/student-data?studentEmail=${
      encodeURIComponent(studentEmail)}`
    : `${Url}/student/student-data`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    console.error('Error in getStudentData', e.message)
    return {}
  }
}

async function getDropEligibleStudents (dateStart) {
  const token = await getToken()
  const url = `${Url}/admin/adminstrative-drop/list?dateStart=${dateStart}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data?.data
  } catch (e) {
    console.error('Error in getDropEligibleStudents', e.message)
    return []
  }
}

async function adminBulkDropStudents (studentsData) {
  const token = await getToken()
  const url = `${Url}/admin/adminstrative-drop/list`

  try {
    const { data: { success } } = await axios.put(url, studentsData, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return success
  } catch (e) {
    console.error('Error in adminBulkDropStudents', e.message)
    return false
  }
}

async function removeStudentFromDropList (attemptId) {
  const token = await getToken()
  const url = `${Url}/admin/adminstrative-drop/list/${attemptId}`

  try {
    const { data: { success } } = await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return success
  } catch (e) {
    console.error('Error in removeStudentFromDropList', e.message)
    return false
  }
}

async function getStudentSubmissions (courseId, queryParams, fromCache) {
  try {
    const token = await getToken()
    const url = `${Url}/admin/student-grade/${courseId}/assignments?${queryParams}`
    const cachedData = studentSubmissionsCache[url]
    if (cachedData && fromCache) return cachedData

    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    studentSubmissionsCache[url] = data
    return data
  } catch (e) {
    console.error('Error in getStudentSubmissions: ', e.message)
    return null
  }
}

async function putCodingAssignmentModifications (
  studentEmail,
  { courseId, cohortId },
  editData
) {
  const token = await getToken()
  const url = cohortId
    ? `${Url}/admin/coding-assignment-grade-modification/${studentEmail}/${courseId}/${cohortId}`
    : `${Url}/admin/coding-assignment-grade-modification/${studentEmail}/${courseId}`
  const { data } = await axios.put(url, editData, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function putAssignmentModifications (
  studentEmail,
  { courseId, cohortId },
  editData) {
  const token = await getToken()
  const url = cohortId
    ? `${Url}/admin/assignment-grade-modification/${studentEmail}/${courseId}/${cohortId}`
    : `${Url}/admin/assignment-grade-modification/${studentEmail}/${courseId}`
  const { data } = await axios.put(url, editData, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function getWritingAssignment ({
  courseId,
  cohortID,
  assignmentUUID,
  studentEmail,
  fileName,
  multiPartAssignment
}) {
  const token = await getToken()

  try {
    const queryParams = `studentEmail=${studentEmail}${multiPartAssignment ? '&multiPartAssignment=true' : ''}`
    // eslint-disable-next-line
    const url = `${Url}/student/writing-assignment/${courseId}/${cohortID}/${assignmentUUID}/${fileName ? fileName : ''}?${queryParams}`
    const response = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'arraybuffer'
      })
    const { data } = response
    if (data && data.error) return { error: data.error }
    return response
  } catch (e) {
    console.error('Error when getting writing assignment', e.message)
    return { error: NO_ASSIGNMENT }
  }
}

async function getCodingAssignment ({
  courseId,
  cohortID,
  assignmentUUID }, params) {
  const token = await getToken()
  const queryParams = new URLSearchParams(params)

  try {
    const url = `${Url}/student/coding-assignment/${courseId}/${cohortID}/${assignmentUUID}?${queryParams}`
    const response = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'arraybuffer'
      })
    const { data } = response
    if (data && data.error) return { error: data.error }
    return response
  } catch (e) {
    console.error('Error when getting coding assignment', e.message)
    return { error: NO_ASSIGNMENT }
  }
}

/**
 * @param {Object} criteria of student criteria
 * @returns {Array} of students based on that criteria
 */
async function getFilteredStudents (criteria) {
  const token = await getToken()
  const url = `${Url}/admin/csv-export/filter`

  try {
    const { data } = await axios.post(url, criteria, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (error) {
    console.error('Error in getFilteredStudents: ', error.message)
  }
}

/**
 * @param {Object} criteria of student criteria
 * @returns {Array} of students based on that criteria
 */
async function getFilteredStudentsV2 (criteria) {
  const token = await getToken()
  const url = `${Url}/admin/v2/csv-export/filter`
  const filteredCriteria = getFilteredCriteria(criteria)

  try {
    const { data } = await axios.post(url, filteredCriteria, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (error) {
    console.error('Error in getFilteredStudentsV2: ', error.message)
  }
}

async function setStudentProgress ({ key, courseId }, studentEmail, sectionData) {
  try {
    const token = await getToken()
    const url = `${Url}/admin/student-exam-grade/${key}/${encodeURIComponent(studentEmail)}/${courseId}`

    console.info(`API start setStudentProgress with intent ${key}`)
    const res = await axios.put(url, sectionData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info(`API done setStudentProgress with intent ${key}`)

    return res
  } catch (error) {
    console.error('Error in setStudentProgress: ', error)
  }
}

async function putParticipationModifications (
  studentEmail,
  { courseId, cohortId },
  editData) {
  const token = await getToken()
  const url = cohortId
    ? `${Url}/admin/participation-grade-modification/${studentEmail}/${courseId}/${cohortId}`
    : `${Url}/admin/participation-grade-modification/${studentEmail}/${courseId}`

  const { data } = await axios.put(url, editData, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

async function getParticipationModifications (studentEmail, courseId) {
  const token = await getToken()
  const url =
    `${Url}/admin/participation-grade-modification/${studentEmail}/${courseId}/participation`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    return {
      error: true,
      message: 'Something went wrong when fetching participation modifications'
    }
  }
}

async function getProctorioReviewUrl (examTag) {
  const token = await getToken()
  const url = `${Url}/admin/proctorio/review-urls`

  const parameters = {
    examTag: examTag,
    launchUrl: `https://${OUTLIER_BASE_DOMAIN}`,
    examStart: `https://${OUTLIER_BASE_DOMAIN}`,
    examTake: `https://${OUTLIER_BASE_DOMAIN}`,
    examEnd: `https://${OUTLIER_BASE_DOMAIN}`,
    examSettings: 'recordvideo,recordaudio,recordscreen,recordwebtraffic,verifyvideo,verifyaudio,verifydesktop,verifyidauto,verifysignature,clipboard,linksonly,closetabs,onescreen,print,downloads,rightclick,agentreentry,calculatorsci'
  }

  console.info(`API start getProctorioReviewUrl`)
  try {
    const { data: { reviewUrl } } = await axios.post(url, parameters, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info(`API done getProctorioReviewUrl`)

    return reviewUrl
  } catch (err) {
    console.error(`API error in getProctorioReviewUrl: ${err.message}`)
    return null
  }
}

async function getYellowdigUrl (courseId, cohortId, type) {
  const token = await getToken()
  const url = new URL(`${Url}/yellowdig/get-url/${courseId}`)

  if (cohortId) url.searchParams.append('cohortId', cohortId)
  if (type) url.searchParams.append('type', type)
  console.info(`API start getYellowdigUrl`)

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info(`API done getYellowdigUrl`)
    return data
  } catch (error) {
    console.error(`API error in getYellowdigUrl ${courseId}: ${error.message}`)
    return { message: error.message }
  }
}
async function getAllNotifications () {
  // const token = await getToken()
  // const url = `${Url}/notifications`

  // console.info(`API start getAllNotifications`)
  // try {
  //     headers: { Authorization: `Bearer ${token}` }
  //   })
  //   console.info(`API done getAllNotifications`)

  //   return data
  // } catch (err) {
  //   console.error(`API error in getAllNotifications: ${err.message}`)
  //   return null
  // }

  const mockResponse = {
    status: 200,
    headers: { 'content-type': 'application/json' },
    data: NOTIFICATIONS
  }

  return mockResponse.data
}

async function logError (data) {
  const token = await getToken()
  const url = `${Url}/monitor/log-frontend-error`

  try {
    const res = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return res
  } catch (error) {
    console.error('Error when sending error logs: ', error.message)
    return null
  }
}

function getUrl (courseUUID, sectionUUID = courseUUID) {
  return `${API_HOST}/dato/files/${courseUUID}/${sectionUUID}`
}

function getFlags () {
  const queryString = window.location.search.slice(1)
  if (!queryString.includes('flag-')) return {}
  const query = queryString.split('&')
  const flags = {}
  query.forEach(value => {
    let [key, flagValue] = value.split('=')
    key = key.replace('flag-', '')
    flags[key] = flagValue
  })
  return flags
}

async function getAllAdmins () {
  const token = await getToken()
  const { data } = await axios.get(`${Url}/admin/access/admins`, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function addAdmin ({ firstName, lastName, email, role }) {
  const token = await getToken()
  const { data } = await axios.post(`${Url}/admin/access/add-admin`, {
    firstName, lastName, email, role
  }, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function getCurrentUserPermissions () {
  const token = await getToken()
  try {
    const response = await axios.get(`${Url}/admin/access/permissions`, {
      headers: { Authorization: `Bearer ${token}` },
      validateStatus: (status) => status
    })
    const { data } = response
    // from backend we expect 403 if user is not an admin
    if (response && response.status === 403) data.isAdmin = false
    if (response && response.status === 200) data.isAdmin = true
    return data
  } catch (error) {
    console.error(error)
    return null
  }
}

async function getRolesAndDescriptions () {
  const token = await getToken()
  const { data } = await axios.get(`${Url}/admin/access/roles`, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function getUsersByRole (queryParams) {
  const token = await getToken()
  const { data } = await axios.get(`${Url}/admin/access/admins`, {
    params: queryParams,
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function updateAdminPermissions (email, payload) {
  const token = await getToken()
  const { data } = await axios.put(`${Url}/admin/access/update-admin/${email}`, payload, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function deleteAdmin (email) {
  const token = await getToken()
  const { data } = await axios.delete(`${Url}/admin/access/delete-admin/${email}`, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function getTuitionVouchers () {
  const { start, done, error } = getApiMessages('getTuitionVouchers')

  console.info(start)
  try {
    const token = await getToken()
    const url = `${Url}/admin/tuition-vouchers`
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info(done)
    return data
  } catch (e) {
    console.error(error)
    return []
  }
}

function getApiMessages (apiName) {
  return {
    start: `API start ${apiName}`,
    done: `API done ${apiName}`,
    error: `API error ${apiName}`
  }
}

async function updateAdmin (name, role, isActive, email, extendedPermissions) {
  const token = await getToken()
  const { data } = await axios.put(`${Url}/admin/access/update-admin/${email}`, {
    name,
    role,
    extendedPermissions,
    active: isActive
  }, {
    headers: { Authorization: `Bearer ${token}` }
  })
  return data
}

async function getStudentRelationships (queryParams, useCache = true) {
  const token = await getToken()
  const searchParams = new URLSearchParams(queryParams)
  const url = `${Url}/admin/relationships` + (queryParams ? `?${searchParams}` : '')
  const cachedData = dataTableCache[url]
  if (cachedData && useCache) return cachedData
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const filteredData = data.filter(({ relationshipName }) => relationshipName)
      .sort((current, next) =>
        current.relationshipName.localeCompare(next.relationshipName)
      )

    dataTableCache[url] = filteredData
    return filteredData
  } catch (error) {
    return { error: true, message: 'Something went wrong' }
  }
}

async function getCreditGrantingInstitutions () {
  const relationships = await getStudentRelationships()

  return getUniqueCreditGrantingInstitutions(relationships)
}

async function getPaymentStatuses () {
  const token = await getToken()
  const url = `${Url}/admin/payment-statuses`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    return { error: true, message: 'Something went wrong' }
  }
}

async function getLastOrders () {
  const token = await getToken()
  const url = `${Url}/admin/last-orders`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    return { error: true, message: 'Something went wrong' }
  }
}

async function checkEmailExists (email) {
  const token = await getToken()
  const url = `${Url}/exists/${email}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data.success
  } catch (error) {
    return false
  }
}

async function mapCSVHeaders (formData) {
  const token = await getToken()
  const url = `${Url}/admin/student-import/map-csv-headers`
  try {
    const { data } = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (e) {
    console.log(e)
    return e.response.data
  }
}

async function mapGGUAdmissionCSVHeaders (formData) {
  const token = await getToken()
  const url = `${Url}/admin/prospect-import/map-csv-headers`
  try {
    const { data } = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (e) {
    console.log(e)
    return e.response.data
  }
}

async function importStudentData (formData) {
  const token = await getToken()
  const url = `${Url}/admin/student-import/import-student-data`

  try {
    const { data } = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (e) {
    console.error('Error when importing student data: ', e.message)
    return e.response.data
  }
}

async function importGGUAdmissionDecision (formData) {
  const token = await getToken()
  const url = `${Url}/admin/prospect-import/import-admission-decision`

  try {
    const { data } = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (e) {
    console.error('Error when importing student data: ', e.message)
    return e.response.data
  }
}

async function getUTMParameters () {
  const token = await getToken()
  const url = `${Url}/analytics/filter-options/utmParameters`

  const { start, done, error } = getApiMessages('getUTMParameters')

  console.info(start)
  try {
    const { data: { options } = {} } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    console.info(done)
    return options
  } catch (e) {
    console.error(error)
    return null
  }
}

async function enableContentGatingForCohort (cohortId) {
  const token = await getToken()
  const url = `${Url}/admin/content-gating-cohort`

  try {
    const response = await axios.put(url, {
      cohortId
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json'
      }
    })

    return response.data
  } catch (e) {
    console.error(e)
    return null
  }
}

async function fetchStudentVouchers (studentId) {
  const token = await getToken()
  const url = `${Url}/admin/tuition-vouchers/${studentId}`
  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return res.data
  } catch (error) {
    console.error(error)
    return null
  }
}

async function getAmazonRefundRequests () {
  const token = await getToken()
  const url = `${Url}/amazon/refund-requests`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (error) {
    const { message } = error || {}
    console.error('Error in getAmazonRefundRequests: ', message)
    return { error: true, message }
  }
}

async function saveRefundDecision (decision) {
  const token = await getToken()
  const url = `${Url}/amazon/save-refund-decision`

  try {
    const { data } = await axios.post(url, decision, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (error) {
    const errorMessage = error?.message || error?.response?.data?.message
    console.error('Error when saving decision for Amazon refund request: ', errorMessage)
    return { error: true, message: errorMessage }
  }
}

async function fetchVoucherFile (voucherId) {
  const token = await getToken()
  const url = `${Url}/admin/tuition-voucher-file/${voucherId}`
  try {
    const res = await axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return res.data
  } catch (error) {
    console.error(error)
    return null
  }
}

async function setVoucherStatus (voucherId, {
  status,
  denialNote
}) {
  const token = await getToken()
  const url = `${Url}/admin/set-tuition-voucher-status/${voucherId}`
  try {
    const res = await axios.put(url, {
      status,
      denialNote
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json'
      }
    })
    return res.data
  } catch (error) {
    console.error(error)
    return null
  }
}

async function enableContentGatingForStudents (cohort, students, lastActive) {
  const token = await getToken()
  const url = `${Url}/admin/content-gating-students`

  try {
    const response = await axios.put(url, {
      students: {
        [cohort]: [...students]
      },
      lastActive
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json'
      }
    })

    return response.data
  } catch (e) {
    console.error(e)
    return null
  }
}

async function sendDropNotification (voucherId) {
  const token = await getToken()
  try {
    const url = `${Url}/admin/tuition-voucher/drop-notification/${voucherId}`
    const res = await axios.put(url, null, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return res.data
  } catch (error) {
    console.error(error)
    return null
  }
}

async function submitTrackedEvent (rawData) {
  console.info('API start submitTrackedEvent')
  const allowedKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  const params = qs.parse(window.location.search.slice(1))
  const utmPropertiesArray = Object.keys(params)
    .filter(key => allowedKeys.includes(key))
    .map(key => ([key, params[key]]))
  const utmProperties = Object.fromEntries(utmPropertiesArray)

  const properties = { ...rawData.properties, ...utmProperties }
  const eventData = { ...rawData, properties }

  try {
    const token = await getToken()
    console.info('API done submitTrackedEvent')
    const url = `${Url}/admin/analytics/track-event`
    const { data } = await axios.post(url, eventData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (err) {
    console.error(`API error in submitTrackedEvent: ${err.message}`)
    return null
  }
}

async function fetchVoucherDetails (studentId, voucherId) {
  const token = await getToken()
  try {
    const url = `${Url}/admin/tuition-vouchers/${studentId}/${voucherId}`
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return res.data
  } catch (error) {
    console.error(error)
    return null
  }
}

async function addRelationship (relationshipData) {
  const token = await getToken()
  const url = `${Url}/admin/relationships/add-new`
  try {
    const { data } = await axios.post(url, relationshipData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

async function updateRelationship (relationshipData) {
  const token = await getToken()
  const url = `${Url}/admin/relationship/update`
  try {
    const { data } = await axios.put(url, relationshipData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

async function captureWGCAnalytics (analyticsData) {
  const token = await getToken()
  const url = `${Url}/admin/analytics/track-event`
  try {
    const { data } = await axios.post(url, analyticsData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

async function getDataTableMetadata ({ tableName }) {
  const token = await getToken()
  const url = `${Url}/table-rows/pg/metadata/${tableName}`

  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return res.data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function getTableView ({ tableName, fromCache }) {
  const token = await getToken()
  const url = `${Url}/table-rows/views/${tableName}`
  const cachedData = dataTableCache[url]
  if (cachedData && fromCache) return cachedData

  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    dataTableCache[url] = res.data

    return res.data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function updateTableView ({ tableName, id, view }) {
  const token = await getToken()
  const url = `${Url}/table-rows/views/${tableName}/${id}`

  try {
    const { data } = await axios.put(url, view, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    console.error('Error in updateTableView', e.message)
    return { data: null, error: e.response?.data?.error }
  }
}

async function deleteTableView ({ tableName, id }) {
  const token = await getToken()
  const url = `${Url}/table-rows/views/${tableName}/${id}`

  try {
    const res = await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return res?.data
  } catch (error) {
    console.error(`API error in deleteTableView ${tableName}: ${error?.message}`)
    return null
  }
}

async function getTableIdValues ({ tableName }) {
  const token = await getToken()
  const url = `${Url}/table-rows/id-values/${tableName}`

  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return res.data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function getTableFilter (data) {
  const token = await getToken()
  const url = `${Url}/table-rows/filter`

  const { limit, offset, sort, format, filters } = data
  const { id, desc } = sort || {}
  const params = {
    ...(limit === 'All' ? {} : { limit: limit || 100 }),
    ...(format && { format }),
    ...(offset && { offset }),
    ...(id && { sort: id, order: desc ? 'DESC' : 'ASC' })
  }

  const { rules, logicalOperator } = filters || {}
  data.filters = { rules, logicalOperator }
  const searchParams = new URLSearchParams(params)

  const urlWithParams = `${url}?${searchParams.toString()}`

  try {
    const res = await axios.post(urlWithParams, data, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return res.data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function getAmazonStudentsProgress (formData) {
  const token = await getToken()
  const url = `${Url}/amazon/progress-csv`

  try {
    const { data } = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'multipart/form-data'
      }
    })

    return { data }
  } catch (e) {
    return { errorMessage: e.message }
  }
}

async function setFinalGrades (studentsGrade, cohortId, courseId) {
  const token = await getToken()
  const url = `${Url}/student/analytics/add-log-event`
  try {
    const { data } = await axios.put(url, {
      event: 'bulk-update-final-grades',
      data: {
        grades: studentsGrade,
        courseId: courseId,
        cohortId: cohortId
      }
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error('Error in setFinalGrades: ', error.message)
    return { error: true, message: error.message }
  }
}

async function uploadELTCSV (formData, fileType) {
  const token = await getToken()
  const url = `${Url}/guild/guild-csv?type=${fileType}`

  try {
    const { data } = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'multipart/form-data'
      }
    })

    return { data }
  } catch (e) {
    console.error('Error uploading ELT CSV file', e.message)
    return { message: e.message, statusCode: e.response?.status }
  }
}

/**
 * @param {String} fileName name of the file it's content to be fetched
 *  if not provided then the list of the uploaded ELTs will be fetched
 * @returns {String} content of the provided ELT file name (data)
 * @returns {Array} of the info of all the ELT files have been uploaded (data?.data)
 */
async function getUploadedELTFiles (fileName = '') {
  const token = await getToken()
  const url = fileName
    ? `${Url}/guild/elt-csv/${fileName}`
    : `${Url}/guild/elt-csv`

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const fetchedData = data?.data || data
    return { data: fetchedData }
  } catch (e) {
    console.error('Error getting uploaded ELT files', e.message)
    return { message: e.message }
  }
}

async function getExamExtensionsTable ({
  offset,
  cohortFilter = '',
  courseFilter = '',
  studentFilter = ''
}) {
  const token = await getToken()
  let url = `${Url}/admin/exam-extensions?limit=10&offset=${offset}&sort=updatedAt`
  url += cohortFilter !== '' ? `&cohortId=${cohortFilter}` : ''
  url += studentFilter !== '' ? `&search=${encodeURIComponent(studentFilter)}` : ''
  url += courseFilter !== '' ? `&courseId=${courseFilter}` : ''

  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return res.data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function getStudentExtensionRecords (studentIds) {
  const token = await getToken()
  const url = `${Url}/admin/assessment-extensions`
  studentIds = { studentIds: studentIds }
  try {
    const res = await axios.post(url, studentIds, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return res.data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function getProspectsData (studentEmail) {
  const token = await getToken()
  const url = `${Url}/prospects/prospect-data?studentEmail=${encodeURIComponent(studentEmail)}`
  console.log('API Start getProspectsData')

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.log('API done getProspectsData')
    return { data, error: null }
  } catch (e) {
    console.error('Error in getProspectsData', e.message)
    return { error: e, data: null }
  }
}

async function getStudentDataByEmailOrId (studentIdOrEmail) {
  const token = await getToken()
  const url = `${Url}/admin/student-data/${studentIdOrEmail}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    console.error('Error in getStudentData', e.message)
    return {}
  }
}

async function removeStudentCache (studentEmail) {
  const token = await getToken()
  const url = `${Url}/student-id-cache/${studentEmail}`

  try {
    const { data } = await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    console.error('Error in removeStudentCache', e.message)
    return {}
  }
}

async function getDataBaseSchema () {
  const token = await getToken()
  const url = `${Url}/table-rows/metadata`
  const cachedData = dataTableCache[url]
  try {
    if (cachedData) return cachedData
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    dataTableCache[url] = data
    return data
  } catch (e) {
    console.error('Error in getDataBaseSchema', e.message)
    return {}
  }
}

async function deleteRecords (table, ids) {
  const token = await getToken()
  const url = `${Url}/table-rows/delete/${table}`
  try {
    const { data } = await axios.post(url, ids, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    console.error('Error in deleteRecord', e.message)
    return { data: null, error: e.response?.data?.error }
  }
}

async function addNewRecord (record, tableName) {
  const token = await getToken()
  const url = `${Url}/table-rows/create/${tableName}`

  const newRecord = {}

  Object.keys(record).forEach(key => {
    newRecord[key] = Array.isArray(record[key])
      ? record[key].map(item => item?.id || item)
      : record[key]
  })

  try {
    const { data } = await axios.post(url, newRecord, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    console.error('Error in addNewRecord', e.message)
    return { data: null, error: e.response?.data?.error }
  }
}

async function updateRecord (record, tableName, id) {
  const token = await getToken()
  const url = `${Url}/table-rows/update/${tableName}`

  const newRecord = {}

  Object.keys(record).forEach((key) => {
    newRecord[key] = Array.isArray(record[key])
      ? record[key].map(item => item?.id || item)
      : record[key]
  })

  try {
    const { data } = await axios.put(url,
      {
        id: id,
        fields: newRecord
      }, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return data
  } catch (e) {
    console.error('Error in updateRecord', e.message)
    return { data: null, error: e.response?.data?.error }
  }
}

async function addNewView (tableName, view) {
  const token = await getToken()
  const url = `${Url}/table-rows/views/${tableName}`

  try {
    const { data } = await axios.post(url, view, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    console.error('Error in addNewView', e.message)
    const error = e.response?.data?.error || e.response?.data?.message
    return { data: null, error }
  }
}

async function addAssessmentExtensions (extensionData) {
  const token = await getToken()
  const url = `${Url}/admin/create/assessment-extensions`
  try {
    const { data } = await axios.post(url, extensionData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    return { errorMessage: e.message }
  }
}

async function putAssessmentExtension (extensionData) {
  const token = await getToken()
  const url = `${Url}/admin/update/assessment-extensions`
  try {
    const { data } = await axios.put(url, extensionData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (e) {
    return { errorMessage: e.message }
  }
}

async function getStudentsCurrentGrade (courseId, cohortName) {
  const token = await getToken()
  const url = `${Url}/student/current-grade/${courseId}/${encodeURIComponent(cohortName)}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function getStudentCertificates (studentEmail) {
  const token = await getToken()
  const url = `${Url}/student/certificates?studentEmail=${encodeURIComponent(studentEmail)}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return {}
  }
}

async function checkStudentProgress (studentIdOrEmail) {
  const token = await getToken()
  const url = `${Url}/student/check-progress/${studentIdOrEmail}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return {
      data,
      success: true
    }
  } catch (error) {
    console.error(error)
    return {
      success: false
    }
  }
}

async function submitEnrollment (enrollmentData) {
  const token = await getToken()
  const url = `${Url}/admin/enroll-student`
  try {
    const { data } = await axios.post(url, enrollmentData, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error('Error in submitEnrollment: ', error.message)
    return { error: true, message: error?.response?.data?.error || error.message }
  }
}

async function mergeAccounts (accountEmails) {
  const token = await getToken()
  const url = `${Url}/admin/merge-accounts`
  try {
    const { data } = await axios.put(url, accountEmails, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return {}
  }
}

async function createTableRecordComment (tableName, recordId, comment) {
  const token = await getToken()
  const params = { table_name: tableName, record_id: recordId, comment: comment }
  const url = `${Url}/admin/table-comments/create`
  try {
    const res = await axios.post(url, params, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return res.data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function getTableRecordComments (tableName, recordId) {
  const token = await getToken()
  const url = `${Url}/admin/table-comments/filter/${tableName}/${recordId}`
  try {
    const res = await axios.post(url, {}, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return res.data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function getPartnerAvailableTokens (partnerEmail) {
  const token = await getToken()
  const url = `${Url}/admin/available-tokens?email=${encodeURIComponent(partnerEmail)}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return {}
  }
}

async function getDegrees () {
  const token = await getToken()
  const url = `${Url}/admin/degrees`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function getAdvisors () {
  const token = await getToken()
  const url = `${Url}/admin/advisors`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function putExamKeyOverride (payload) {
  const token = await getToken()
  const url = `${Url}/admin/override-exam-keys`
  try {
    const { data } = await axios.put(url, payload, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return { error: true, message: error.message }
  }
}

async function getProspectDetails (prospectId) {
  const token = await getToken()
  const url = `${Url}/admin/prospects/${prospectId}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return { error: true, message: error.message }
  }
}

async function getGGUProspects (queryParams = {}) {
  const token = await getToken()
  const url = `${Url}/admin/ggu-prospects`
  try {
    const { data } = await axios.get(url, {
      params: queryParams,
      headers: { Authorization: `Bearer ${token}` }
    })

    return data
  } catch (e) {
    return { error: true, message: 'Something went wrong ' }
  }
}

async function updateProspectDetails (prospectId, payload) {
  const token = await getToken()
  const url = `${Url}/admin/prospects/${prospectId}`
  try {
    const { data } = await axios.put(url, payload, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return { error: true, message: error.message }
  }
}

async function approveProspect (prospectId, body) {
  const token = await getToken()
  const url = `${Url}/admin/approve-prospect/${prospectId}`
  try {
    const { data } = await axios.post(url, body, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return { data }
  } catch (error) {
    console.error(error)
    return { error: true, message: error.message }
  }
}

async function getGradingCompData (courseId, cohortName) {
  const token = await getToken()
  const url = `${Url}/admin/grading-comp-check`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function getMaxParticipationGrade (courseId) {
  const token = await getToken()
  const url = `${Url}/course/participation-max/${courseId}`
  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return 0
  }
}

async function getCoursesLicenses (queryParams) {
  const token = await getToken()
  const url = `${Url}/admin/token-licenses`
  try {
    const { data } = await axios.get(url, {
      params: { ...queryParams },
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function voidTokens (tokenIds) {
  const token = await getToken()
  const url = `${Url}/admin/void-tokens`
  try {
    const { data } = await axios.post(url, { tokenIds }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return { error: true, message: error.message }
  }
}

async function upsertBulkVipStudents (body) {
  const token = await getToken()
  const url = `${Url}/admin/create-update-vip-bulk`
  try {
    const { data } = await axios.post(url, body, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return {}
  }
}

async function searchAIViolations (body) {
  const token = await getToken()
  const url = `${Url}/admin/search-ai-violations`
  try {
    const { data } = await axios.post(url, body, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    console.error(error)
    return []
  }
}

async function updateAIViolation (params, body) {
  const { cohortId, assessmentName, studentId } = params
  const token = await getToken()
  const url = `${Url}/admin/ai-violation/${cohortId}/${encodeURIComponent(
    assessmentName
  )}/${studentId}`
  try {
    const { data: { success } } = await axios.put(
      url,
      body,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    return success
  } catch (error) {
    console.error(error)
    return {}
  }
}
