import qs from 'qs'
import { parseBooleanObject } from './utilities/index'
import { OUTLIER_API_BASE_URL } from './Constants'

const API_HOST = OUTLIER_API_BASE_URL
const allowATWriteFlag = allowATWrite()
const hasCreateBulkCohortFlag = isCreateBulkCohort()
const isStudentProgressFlag = isStudentProgress()
const hasAcademicIntegrityFlag = hasAcademicIntegrity()
const yellowdigReleaseDate = 1624431600 // Jun-23-2021 00:00:00 PDT
const philosophyUpdatedGradingReleaseDate = 1641369600 // Jan-05-2022 00:00:00 PST
const collegeWritingIUpdatedAssignmentReleaseDate = 1667977200 // Nov-09-2022 00:00:00 PST
const proctorioExamUUIDReleaseDate = 1633503600 // Oct-06-2021 00:00:00 PDT
const participationPhilosophyChangeDate = 1663138800 // Sep-14-2022 00:00:00 PDT
// The following are some cohorts with start date before yellowdigReleaseDate,
// but will switch from Slack to Yellowdig on yellowdigReleaseDate.
const yellowdigCohorts = [
  'rectH7t6qtR1uqXdo',
  'recPtbKtIzhRj9NiR',
  'recDbm9nfEe87ldTu',
  'recMBCdcSbueqpU4o',
  'recP8v8IvLwAZkPW7',
  'reckNVrfLGBsaFZxU',
  'rec2TEv3OCMjjnV6f',
  'recBII5Fk99MpJNER',
  'rech0qcb5PkmvsKVl',
  'recEUNnrE8SqGjZzC',
  'recEq7bhNe8CQuyL5',
  'recPYjfSEBLEEjm6o',
  'rec634GdbCF66mRSP',
  'recJhWdWvyJBDWokM',
  'recak3atJjMOM4qfa',
  'recuxu8HetJ8A8Xut',
  'reczLpJ8FrjAqTrao',
  'recLqSJsojJIDCSjM',
  'recRg8WPROvn0zsZ0',
  'rec43b9YdgTGblgN8',
  'recQ5UaLmcPKVdWMI',
  'recTNtWRyaY6UWI2x',
  'recWc9sj7fUPMZDKg',
  'recpX8tUw3faokdcz',
  'recX7NtB7Ydh1jeJR',
  'reczFE3Qddg4I3dMI',
  'recpkM4VZUsgIEqA0',
  'recub5DQMcvRvcm9a',
  'recQJX9h1RdTPTAU8',
  'recekjmaSuuQy0tjx'
]

const tokensLaunchDate = '2022-04-11'

export {
  allowATWriteFlag,
  yellowdigReleaseDate,
  philosophyUpdatedGradingReleaseDate,
  collegeWritingIUpdatedAssignmentReleaseDate,
  proctorioExamUUIDReleaseDate,
  participationPhilosophyChangeDate,
  yellowdigCohorts,
  getApiHost,
  getCourseIds,
  invert,
  isOutlierEngineer,
  hasCreateBulkCohortFlag,
  isStudentProgressFlag,
  tokensLaunchDate,
  additionalCourseIds,
  courseIdToName,
  additionalCourseIdToName,
  API_HOST,
  hasAcademicIntegrityFlag,
  isConceptTagEnabled
}

function courseIdToName (id) {
  return invert(getCourseIds())[id]
}

function isOutlierEngineer (email) {
  if (!email) return false
  return email?.endsWith('@engineering.outlier.org')
}

function allowATWrite () {
  const flags = getFlags()
  const allowWrite = flags.allowWrite
  return !!allowWrite
}

function isCreateBulkCohort () {
  const flags = getFlags()
  const bulkCohort = flags.bulkCohort
  return !!bulkCohort
}

function isStudentProgress () {
  const flags = getFlags()
  const studentProgress = flags.studentProgress
  return !!studentProgress
}

function hasAcademicIntegrity () {
  const flags = getFlags()
  return !!flags.academicIntegrity
}

function isConceptTagEnabled () {
  const urlParams = new URLSearchParams(window.location.hash.split('?')[1])
  const conceptTags = urlParams.has('conceptTags')
  return !!conceptTags
}

function getFlags () {
  const query = qs.parse(window.location.search.slice(1))
  Object.entries(query).forEach(([k, v]) => {
    delete query[k]
    if (k.indexOf('flag-') !== 0) return
    query[k.replace('flag-', '')] = v
  })
  return parseBooleanObject(query)
}

function invert (obj) {
  const inverted = {}
  Object.keys(obj).forEach(function (k) {
    const v = obj[k]
    inverted[v] = k
  })
  return inverted
}

function getCourseIds () {
  return {
    test: 'test-course',
    calculus: 'b227c462-332c-40e0-8735-ea3af6f11661',
    psychology: '1e2f466d-049a-41e7-af53-74afbfa9d87b',
    'y.calculus': 'ea88ffd3-5c59-49d5-89b4-b9f009dde9ac',
    statistics: 'ckdampe3b00003g5m6l85b1s5',
    principlesofeconomics: 'ckxyytpau00003f5xhe3sf4xj',
    philosophy: 'ckgqsu5lf00003h5lzot6as6x',
    collegealgebra: 'ckw6tiqy100003e5xexynd0dz',
    'collegewriting-i': 'cku4ayye700003g5vsb6jgmhb',
    'collegewriting-ii': 'cl31r155p00003f63wxa1sq4z',
    'physics-i': 'cl0h6maex00003e625n0dtois',
    'calculus-ii': 'cl9pxqbj300003b685ixsl873',
    business: 'cku4ayarl00003g5v8zya64ih',
    microeconomics: 'ckiawlgvw00003h5o8c6u6rog',
    professionalcommunication: 'cl8929un900003b68j8k16w6a',
    collegesuccess: 'ckyp0xzra00003e5zdb9d8mta',
    precalculus: 'ckp356yiy00003h5vpz3fbu1h',
    macroeconomics: 'ckp3587wu00003h5v6jfbv37v',
    'computerscience-i': 'cl0sd8la200003e62p8cxq2ey',
    managerialaccounting: 'cl0h7sg9f00003e620m2mlma8',
    financialaccounting: 'ckp359ieh00003h5vm158kdt0',
    sociology: 'ckp3592gx00003h5vsd5ijmp9',
    astronomy: 'ckdf2158p00003g5milp6mvj8',
    barnardhighlights: 'cktt8s66100003g5v8872yin9',
    content: 'cl2grnv7z00003f6302c1ccbf',
    'calculus.plus': 'cl8chwjku00003b68m0uulv59',
    'computerscience-i.plus': 'cla70y6pd00003b68xl5o929f',
    'collegealgebra.plus': 'cl8cc3qps00003b68vf2xvbx2',
    'collegewriting-i.plus': 'cl8bpkss900003b68lww2m8az',
    'collegewriting-i-v2.plus': 'clpx286t000013b6buj1q8rgq',
    'astronomy.plus': 'cl9ipiyvr00003b68940h4yf7',
    'business.plus': 'cl8chrori00003b68iatqg9ds',
    'financialaccounting.plus': 'cl8cicil200003b686pscg7nt',
    'macroeconomics.plus': 'cl8cifhox00003b683k6qy7gk',
    'microeconomics.plus': 'cl8cieub000003b68hturuo2t',
    'philosophy.plus': 'cl8cig0e700003b68mgbqnry2',
    'psychology.plus': 'cl8chvsoy00003b68m0t56gfv',
    'sociology.plus': 'cl8cib1gy00003b682ct750ba',
    'statistics.plus': 'cl8ci9dna00003b684igglvbn',
    'precalculus.plus': 'cl8d5dbhz00003b689avjvmsv',
    'principlesofeconomics.plus': 'cl8cigudr00003b68gdw3vcxn',
    'professionalcommunication.plus': 'clg6ywvo000003b676omcaa9t',
    'collegewriting-ii.plus': 'clg6yvuyc00003b67eoyh7lz1',
    'business.ext': 'clix6ozhf00003b67f9cfxryn',
    'astronomy.ext': 'clzbmp7cm00003b6evkcd0srr',
    'microeconomics.ext': 'clzbrh8hn00003b6f7yjprec7',
    'macroeconomics.ext': 'clzbr1j2400003b6er0vculdk',
    'sociology.ext': 'clixipgk800003b6727vle7hi',
    'principlesofeconomics.ext': 'clixiovxz00003b671j9lggjw',
    'computerscience-i.ext': 'clixingmb00003b67f9nlibtx',
    'calculus.ext': 'clj65g8fi00003b670xricu75',
    'psychology.ext': 'clixio9dp00003b67fimpf4h2',
    'statistics.ext': 'clix6t3q600003b67l08i0ljb',
    'collegealgebra.ext': 'clix6s4i500003b67roqr1l1p',
    'precalculus.ext': 'clix6q93y00003b67mbuz6lm4',
    'financialaccounting.ext': 'clix6mm3e00003b67x74owz2b',
    'professionalcommunication.ext': 'clixiqdxw00003b67c8r80fb3',
    'projectmanagement.cert': 'clieyyjpp00003b69h8dnhmtu',
    'dataanalytics-i.cert': 'clgb29uhc00003b67wtuupjqy',
    'salesoperations.cert': 'clgclvp9o00003b675vmu4250',
    'digitalmarketing-i.cert': 'clgcltmgf00003b675y2hecxi',
    'dataanalytics-ii.cert': 'cloipjvx900003b6c9irc9j7d',
    'digitalmarketing-ii.cert': 'cloipl0py00003b6cxbtn9t2s',
    'itsupport.cert': 'cljst951400003b69tq4jnltv'
  }
}

function getApiHost () {
  let apiHostFromLocalStorage = localStorage.apiHost
  const apiHostFromURL =
    window.location.search && qs.parse(window.location.search.slice(1)).apiHost

  if (!apiHostFromLocalStorage && !apiHostFromURL) {
    return API_HOST
  }

  if (apiHostFromURL) {
    localStorage.apiHost = apiHostFromURL
    apiHostFromLocalStorage = localStorage.apiHost
  }

  return apiHostFromLocalStorage
}

function additionalCourseIds () {
  return {
    test: ['test-course'],
    token: ['ckv72bcw70000a2t95qftcx6r'],
    calculus: ['b227c462-332c-40e0-8735-ea3af6f11661'],
    psychology: ['1e2f466d-049a-41e7-af53-74afbfa9d87b'],
    statistics: ['ckdampe3b00003g5m6l85b1s5'],
    astronomy: ['ckdf2158p00003g5milp6mvj8', 'ckzmyc33d00003e5z5ju9w077'],
    collegealgebra: ['ckw6tiqy100003e5xexynd0dz'],
    professionalcommunication: ['cl8929un900003b68j8k16w6a'],
    collegesuccess: ['ckyp0xzra00003e5zdb9d8mta'],
    precalculus: ['ckp356yiy00003h5vpz3fbu1h'],
    principlesofeconomics: ['ckxyytpau00003f5xhe3sf4xj'],
    'computerscience-i': ['cl0sd8la200003e62p8cxq2ey'],
    managerialaccounting: ['cl0h7sg9f00003e620m2mlma8'],
    macroeconomics: ['ckp3587wu00003h5v6jfbv37v'],
    'collegewriting-i': ['cku4ayye700003g5vsb6jgmhb'],
    'collegewriting-ii': ['cl31r155p00003f63wxa1sq4z'],
    'physics-i': ['cl0h6maex00003e625n0dtois'],
    'calculus-ii': ['cl9pxqbj300003b685ixsl873'],
    business: ['cku4ayarl00003g5v8zya64ih'],
    financialaccounting: ['ckp359ieh00003h5vm158kdt0'],
    sociology: ['ckp3592gx00003h5vsd5ijmp9'],
    philosophy: ['ckgqsu5lf00003h5lzot6as6x'],
    microeconomics: ['ckiawlgvw00003h5o8c6u6rog'],
    'y.calculus': ['ea88ffd3-5c59-49d5-89b4-b9f009dde9ac'],
    'calculus.plus': ['cl8chwjku00003b68m0uulv59'],
    'computerscience-i.plus': ['cla70y6pd00003b68xl5o929f'],
    'collegealgebra.plus': ['cl8cc3qps00003b68vf2xvbx2'],
    'collegewriting-i.plus': ['cl8bpkss900003b68lww2m8az', 'clpx286t000013b6buj1q8rgq'],
    'astronomy.plus': ['cl9ipiyvr00003b68940h4yf7'],
    'business.plus': ['cl8chrori00003b68iatqg9ds'],
    'financialaccounting.plus': ['cl8cicil200003b686pscg7nt'],
    'macroeconomics.plus': ['cl8cifhox00003b683k6qy7gk'],
    'microeconomics.plus': ['cl8cieub000003b68hturuo2t'],
    'philosophy.plus': ['cl8cig0e700003b68mgbqnry2'],
    'psychology.plus': ['cl8chvsoy00003b68m0t56gfv'],
    'sociology.plus': ['cl8cib1gy00003b682ct750ba'],
    'statistics.plus': ['cl8ci9dna00003b684igglvbn'],
    'precalculus.plus': ['cl8d5dbhz00003b689avjvmsv'],
    'principlesofeconomics.plus': ['cl8cigudr00003b68gdw3vcxn'],
    'professionalcommunication.plus': ['clg6ywvo000003b676omcaa9t'],
    'collegewriting-ii.plus': ['clg6yvuyc00003b67eoyh7lz1'],
    'business.ext': ['clix6ozhf00003b67f9cfxryn'],
    'sociology.ext': ['clixipgk800003b6727vle7hi'],
    'principlesofeconomics.ext': ['clixiovxz00003b671j9lggjw'],
    'computerscience-i.ext': ['clixingmb00003b67f9nlibtx'],
    'calculus.ext': ['clj65g8fi00003b670xricu75'],
    'psychology.ext': ['clixio9dp00003b67fimpf4h2'],
    'statistics.ext': ['clix6t3q600003b67l08i0ljb'],
    'collegealgebra.ext': ['clix6s4i500003b67roqr1l1p'],
    'precalculus.ext': ['clix6q93y00003b67mbuz6lm4'],
    'financialaccounting.ext': ['clix6mm3e00003b67x74owz2b'],
    'professionalcommunication.ext': ['clixiqdxw00003b67c8r80fb3'],
    'projectmanagement.cert': ['clieyyjpp00003b69h8dnhmtu'],
    'dataanalytics-i.cert': ['clgb29uhc00003b67wtuupjqy'],
    'salesoperations.cert': ['clgclvp9o00003b675vmu4250'],
    'digitalmarketing-i.cert': ['clgcltmgf00003b675y2hecxi'],
    'dataanalytics-ii.cert': ['cloipjvx900003b6c9irc9j7d'],
    'digitalmarketing-ii.cert': ['cloipl0py00003b6cxbtn9t2s'],
    'itsupport.cert': ['cljst951400003b69tq4jnltv']
  }
}

function additionalCourseIdToName (id) {
  const courseName = Object.keys(additionalCourseIds()).find(
    courseName => additionalCourseIds()[courseName].includes(id)
  )
  return additionalCourseIds()[courseName]
}
