import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  FormGroup,
  Dropdown,
  DropdownToggle
} from 'reactstrap'
import DropdownIcon from '../../assets/icons/dropdown-arrow.svg'
import ModalComponent from '../ModalComponent/ModalComponent'
import './EditGradeModal.css'
import {
  ButtonWrapper,
  EditWrapper,
  GradeText,
  PrimaryButton,
  SecondaryButton,
  SectionSubTitle,
  SectionTitle,
  Text,
  TextArea,
  TextInput,
  Tooltip,
  StyledDropdownMenu,
  DropDownMenuItem,
  DropdownWrapper
} from './styled/AssessmentModalStyles'
import TooltipIcon from '../../assets/icons/icon-tooltip.svg'
import EditConfirmationModal from './EditConfirmationModal'
import { isValidUrl } from '../../utilities/gradeUtils'
import { checkIfRetakeExists } from '../../utilities/examUtils'
import api from '../../api'
import { useParams } from 'react-router-dom'

const changeReasons = [
  'Academic integrity violation',
  'Frontend bug or display error',
  'Answer format requirements were not clear',
  'Student error',
  'Regrading after further review',
  'Other'
]

const selectReason = 'Please select a reason for this change'
function EditAssessmentGradeModal ({
  showModalHandler,
  show,
  grade,
  title,
  section,
  isUpdating,
  showParentModalHandler,
  handleEditAssessmentModal,
  studentProgress
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [cohortMenuOpen, setCohortMenuOpen] = useState(false)
  const [notes, setNotes] = useState('')
  const [score, setScore] = useState('')
  const [zendeskUrl, setZendeskUrl] = useState('')
  const [error, setError] = useState(false)
  const [reason, setReason] = useState(selectReason)
  const [scoreError, setScoreError] = useState(false)
  const [urlError, setUrlError] = useState(false)
  const [assessmentEditData, setAssessmentEditData] = useState(null)
  const match = useParams()
  const [preferredName, setPreferredName] = React.useState('')
  const { studentEmail } = match

  React.useEffect(() => {
    const fetchStudentData = async () => {
      const { preferredName: pName = '' } =
        await api.getStudentData(studentEmail)
      setPreferredName(pName)
    }
    fetchStudentData()
  }, [studentEmail])

  const checkFields = () => {
    if (isUpdating) return
    if (notes === '' && reason === 'Other') return setError(true)

    const hasNoReasonAndScore = reason === selectReason ||
    score === ''

    if (hasNoReasonAndScore) return setError(true)

    setError(false)
    if (score > 100) return setScoreError(true)
    if (!isValidUrl(zendeskUrl)) return setUrlError(true)

    const examVersionsLength = section?.examVersions.length
    const sectionUuidToEdit = checkIfRetakeExists(studentProgress, section)
      ? section?.examVersions[examVersionsLength - 1]?.uuid
      : section?.chapter_uuid
    const editData = {
      sectionUuid: sectionUuidToEdit,
      to: Number(parseFloat(score).toFixed(2)),
      from: grade || 0,
      reason,
      note: notes,
      zendeskUrl,
      data: {
        [sectionUuidToEdit]: {
          sectionPercentage: Number(parseFloat(score).toFixed(2)),
          [sectionUuidToEdit]: Number(parseFloat(score).toFixed(2))
        }
      }
    }

    if (notes !== '') editData.note = notes
    if (zendeskUrl !== '') {
      if (!isValidUrl(zendeskUrl)) return setUrlError(true)
      editData.zendeskUrl = zendeskUrl
    }
    setAssessmentEditData(editData)
    setShowConfirmationModal(true)
  }

  const confirmationSubmit = () => {
    handleEditAssessmentModal(assessmentEditData)
  }

  const isDisabled =
    !zendeskUrl ||
    !changeReasons.includes(reason) ||
    (reason === 'Other' && !notes)

  const toggleCohortMenu = () => setCohortMenuOpen((prevState) => !prevState)
  return (
    <>
      <ModalComponent show={show} handleClose={showModalHandler}>
        <EditWrapper>
          <SectionTitle>{title}</SectionTitle>
          <SectionSubTitle>Earned Grade</SectionSubTitle>
          <GradeText>
            {grade ? `${grade}%` : 'N/A'}
          </GradeText>
          <SectionSubTitle>
            Adjusted Grade{' '}
            <Tooltip>
              <img
                src={TooltipIcon}
                className='ml-2 mb-1'
                width='16'
                height='16'
                alt='tool-tip'
              />
              <span>
                <p>Please enter a number between 0 and 100</p>
              </span>
            </Tooltip>
          </SectionSubTitle>
          {((error && score === '') || scoreError) && (
            <div className='text-error'>
              Please enter a number between 0 and 100
            </div>
          )}
          <TextInput
            type='number'
            data-testid='scoreInput'
            className={
              (error && score === '') || scoreError
                ? 'text-area-error'
                : 'text-area'
            }
            value={score}
            onChange={(e) => {
              setScore(e.target.value)
            }}
          />
          <SectionSubTitle
            className={classNames({
              sectionError: error && reason === selectReason
            })}
          >
            Change Reason
          </SectionSubTitle>
          {error && reason === selectReason && (
            <div className='text-error'>Select a reason for the change</div>
          )}
          <FormGroup className='form-group'>
            <DropdownWrapper data-testid='changeReasonDropdown'>
              <Dropdown
                className='dropdown'
                isOpen={cohortMenuOpen}
                toggle={toggleCohortMenu}
              >
                <DropdownToggle
                  style={{
                    ...dropdownStyle
                  }}
                >
                  {reason}
                  <img
                    style={{
                      transform: cohortMenuOpen ? 'rotate(180deg)' : '',
                      transition: 'transform 0.3s'
                    }}
                    src={DropdownIcon}
                    alt='dropdown-icon'
                  />
                </DropdownToggle>

                <StyledDropdownMenu style={{ width: '100%' }}>
                  {changeReasons.map((reason, index) => (
                    <DropDownMenuItem
                      data-testid='changeReasonOption'
                      key={index}
                      onClick={() => {
                        setReason(reason)
                      }}
                    >
                      {reason}
                    </DropDownMenuItem>
                  ))}
                </StyledDropdownMenu>
              </Dropdown>
            </DropdownWrapper>
          </FormGroup>
          <SectionSubTitle>Notes</SectionSubTitle>
          {error && notes === '' && reason === 'Other' && (
            <div className='text-error'>Add a note about the change</div>
          )}
          {reason === 'Other' && (
            <Text data-testid='toggle-other-field'>
              This will be visible to students on the course homepage.
            </Text>
          )}
          <TextArea
            className={
              error && notes === '' && reason === 'Other'
                ? 'text-area-error'
                : 'text-area'
            }
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value)
            }}
          />
          <SectionSubTitle> Zendesk URL</SectionSubTitle>
          {error && zendeskUrl === '' && (
            <div className='text-error'>Add a Zendesk Ticket URL</div>
          )}
          {!error && urlError && (
            <div className='text-error'>Add a Valid Zendesk Ticket URL</div>
          )}
          <TextArea
            data-testid='zendeskUrlInput'
            height='60px'
            className={
              (error && zendeskUrl === '') || urlError
                ? 'text-area-error'
                : 'text-area'
            }
            value={zendeskUrl}
            onChange={(e) => {
              setZendeskUrl(e.target.value)
            }}
          />
          <ButtonWrapper top='22px'>
            <PrimaryButton
              disabled={isDisabled}
              className='btn-custom btn-primary button'
              onClick={checkFields}
            >
              {isUpdating ? <i className='fa fa-spinner fa-spin' /> : 'Submit'}
            </PrimaryButton>
            <SecondaryButton
              className='btn-custom btn-secondary button'
              onClick={showModalHandler}
            >
              Cancel
            </SecondaryButton>
          </ButtonWrapper>
        </EditWrapper>
      </ModalComponent>
      {showConfirmationModal && <EditConfirmationModal
        show={showConfirmationModal}
        title={title}
        name={preferredName}
        isUpdating={isUpdating}
        showParentModalHandler={showModalHandler}
        showAncestorModalHandler={showParentModalHandler}
        handleEditAssessmentModal={confirmationSubmit}
        showModalHandler={() => setShowConfirmationModal(false)}
        editData={assessmentEditData}
      />}
    </>
  )
}

const dropdownStyle = {
  width: '100%',
  border: '1px solid',
  background: '#1D1D1F',
  boxSizing: 'border-box',
  borderRadius: '5px',
  display: 'flex',
  borderColor: '#77B1AF',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '19px',
  marginBottom: '12px',
  height: '40px'
}

EditAssessmentGradeModal.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired
}

export default EditAssessmentGradeModal
